import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import getOr from "lodash/fp/getOr";
import compose from "lodash/fp/compose";
import merge from "lodash/fp/merge";
import classNames from "classnames";
import queryString from "query-string";

import Routes from "config/routes";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import StepBreadcrumb from "components/StepBreadcrumb";
import RoomCard from "components/RoomCard";
import Heading from "components/Heading";
import RoomDetailsSideBarModal from "components/RoomDetailsSideBarModal";
import NoAvailModal from "components/NoAvailModal";
import EditAvailCheckModal from "components/EditAvailCheckModal";
import Navbar from "components/Navbar";
import RoomSummaryBar from "components/RoomSummaryBar";
import LoadingSpinner from "components/LoadingSpinner";
import Basket from "components/Basket";
import Notice from "components/Notice";
import {
  addRoomReservationToBooking,
  removeRoomReservationFromBooking,
} from "features/booking/bookingSlice";
import { setRoomDetails } from "features/room/roomSlice";
import { setSelectedPropertyId } from "features/property/propertySlice";
import {
  setAvailCheckParams,
  setCommonSearchParams,
  setRoomsOccupancy,
} from "features/booking/bookingSlice";
import api from "utils/api";
import { useSearchParams } from "hooks";
import { childrenCount, getCommonSearchParams, childrenAgesStr } from "utils/helpers";
import { getSelectedProperty } from "selectors/selectedProperty";
import { trackAddReservationToCartEvent } from "utils/analytics";
import { getRestrictionMessage } from "utils/restrictedDates";
import { selectAvailCheckParams } from "selectors/booking";
import { STEPS } from "utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {},
  mainContent: {
    flexDirection: "column",
    margin: "1rem auto",
    width: theme.contentSize.mobilePageWidth,
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      width: theme.contentSize.pageWidth,
      maxWidth: theme.contentSize.maxPageWidth,
    },
  },
  roomCardContainer: {
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  splitContent: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      flexDirection: "row",
    },
  },
  detailsContainer: {
    flexGrow: 1,
  },
  basket: {
    [theme.breakpoints.up(theme.breakpoints.values.tablet)]: {
      marginLeft: "20px",
      flexShrink: 0,
    },
  },
  hidden: {
    display: "none",
  },
  notice: {
    marginBottom: theme.spacing(2),
  },
}));

const ChooseRoom = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedPropertyId = useSelector((state) => state.property.selectedPropertyId);
  const selectedProperty = useSelector(getSelectedProperty);
  const roomReservations = useSelector((state) => state.booking.roomReservations);
  const roomsOccupancy = useSelector((state) => state.booking.roomsOccupancy);
  const commonSearchParams = useSelector((state) => state.booking.commonSearchParams);
  const roomOfRooms = getOr(1, ["state", "roomOfRooms"], location);
  const roomDetails = useSelector((state) => state.room.roomDetails);
  const availCheckParams = useSelector(selectAvailCheckParams(roomOfRooms));
  const searchParams = useSearchParams();

  const [availabilities, setAvailabilities] = useState([]);
  const [bookingRestrictions, setBookingRestrictions] = useState({});
  const [roomDetailsToShow, setRoomDetailsToShow] = useState();
  const [roomDetailsAvails, setRoomDetailsAvails] = useState([]);
  const [roomDetailsModalOpen, setRoomDetailsModalOpen] = useState(false);
  const [nights, setNights] = useState(null);
  const [noAvailModalOpen, setNoAvailModalOpen] = useState(false);
  const [editAvailCheckModalOpen, setEditAvailCheckModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleEditAvailCheckModalOpen = () => {
    if (roomReservations.length > 0) {
      setEditAvailCheckModalOpen(true);
    } else {
      history.push(Routes.PLAN_STAY);
    }
  };

  const handleEditAvailCheckModalClose = () => {
    setEditAvailCheckModalOpen(false);
  };

  const handleNoAvailModalOpen = () => {
    setNoAvailModalOpen(true);
  };

  const handleShowRoomDetails = (unitGroupCode) => {
    setRoomDetailsToShow(findRoomDetailsFromUnitGroupCode(unitGroupCode));
    setRoomDetailsAvails(lookupAvailabilitiesForUnitGroupCode(unitGroupCode));
    setRoomDetailsModalOpen(true);
  };

  const handleRoomDetailsModalClose = () => {
    setRoomDetailsModalOpen(false);
  };

  const handleSelectReservation = (availability, event) => {
    // Add the room reservation
    dispatch(addRoomReservationToBooking({ reservation: availability, roomOfRooms }));
    trackAddReservationToCartEvent(availability);

    // Pass the room id to the addons page for selecting services
    let addOnsParams = { ...availCheckParams };
    addOnsParams["ratePlanId"] = availability.ratePlan.id;

    dispatch(setAvailCheckParams({ roomIndex: roomOfRooms, ...addOnsParams }));

    props.history.push({
      pathname: Routes.SELECT_ADDONS,
      data: {
        queryParams: addOnsParams,
        availability: availability,
      },
      state: {
        roomOfRooms,
      },
    });
  };

  const findRoomDetailsFromUnitGroupCode = (availabilityUnitGroupCode) => {
    // Get the internal property id from the code
    return (
      roomDetails.find((room) => {
        return (
          room.pms_id === availabilityUnitGroupCode &&
          room.property_codes.includes(selectedPropertyId)
        );
      }) || {}
    );
  };

  const lookupAvailabilitiesForUnitGroupCode = (availabilityUnitGroupCode) => {
    return availabilities.find((a) => a.code === availabilityUnitGroupCode)?.availabilities || [];
  };

  const sortUnitGroupsByPreference = (unitGroups, preferredUnitGroups) => {
    // Create a map of preferred unit groups for quick lookup and order determination
    const preferredOrderMap = new Map(preferredUnitGroups.map((code, index) => [code, index]));

    // Define a compare function for sorting
    function compare(a, b) {
      const aIndex = preferredOrderMap.has(a.code) ? preferredOrderMap.get(a.code) : Infinity;
      const bIndex = preferredOrderMap.has(b.code) ? preferredOrderMap.get(b.code) : Infinity;

      // Units in preferred groups should come before those that are not
      // If both are preferred, their order is determined by their position in the preferred array
      // If neither is preferred, their order remains unchanged
      return aIndex - bIndex;
    }

    // Create a copy of the unitGroups array to avoid mutating the original array
    const sortedUnitGroups = [...unitGroups].sort(compare);

    return sortedUnitGroups;
  };

  const groupAvailsByUnitGroups = (availabilities, preferredUnitGroups) => {
    // Step 1: Group availabilities by unit group code
    var groupedAvails = {};
    availabilities.forEach((avail) => {
      if (groupedAvails[avail.unitGroup.code]) {
        groupedAvails[avail.unitGroup.code].push(avail);
      } else {
        groupedAvails[avail.unitGroup.code] = [avail];
      }
    });

    // Step 2: Sort each group by price
    Object.keys(groupedAvails).forEach((key) => {
      groupedAvails[key].sort((a, b) => a.totalGrossAmount.amount - b.totalGrossAmount.amount);
    });

    // Step 3: Convert into an array
    let groupedAvailsArray = Object.keys(groupedAvails).map((key) => ({
      code: key,
      availabilities: groupedAvails[key],
    }));

    // Step 4: Sort according to the preferred unit groups
    groupedAvailsArray = sortUnitGroupsByPreference(groupedAvailsArray, preferredUnitGroups);

    return groupedAvailsArray;
  };

  const adjustAvailabilitiesWithReservationsInCart = (availabilities) => {
    // Subtract units found in cart from availableUnits
    roomReservations.forEach((reservationInCart) => {
      availabilities.forEach((availability) => {
        // Decrease the number of available units for each of the rates for this unit group
        if (availability.unitGroup.id === reservationInCart.unitGroup.id) {
          availability.availableUnits -= 1;
        }
      });
    });

    // Remove all availabilities that have no available units after
    // subtracting items in cart
    const availabilitiesWithAvailableUnits = availabilities.filter((availability) => {
      return availability.availableUnits > 0;
    });

    return availabilitiesWithAvailableUnits;
  };

  const loadAvailability = () => {
    // Make sure no avail modal is closed
    setNoAvailModalOpen(false);

    const queryParams = queryString.parse(location.search);

    if (queryParams.roomOccupancies) {
      // Multi-room mode.
      // When roomOccupancies is passed, we are starting in multi-room mode

      // Comes in format roomOccupancies=[[2,1]]
      // Need to convert to the correct format - i.e [{adults: 2}, {children: 1}]
      const formattedOccupancies = JSON.parse(queryParams.roomOccupancies).map((occ) => {
        return { adults: occ[0], children: occ[1] };
      });

      // Pull out the adult and child could for the first room index
      queryParams.adults = formattedOccupancies[0]?.adults?.toString();
      queryParams.childrenAges = childrenAgesStr(formattedOccupancies[0]?.children || 0);

      dispatch(setRoomsOccupancy(formattedOccupancies));
    } else if (roomsOccupancy.length == 0) {
      // Single room mode
      // - Room occupancies not set up yet
      // - Set up room occupancy from the adults and children passed in
      dispatch(
        setRoomsOccupancy([
          {
            adults: parseInt(queryParams.adults),
            children: childrenCount(queryParams),
          },
        ])
      );
    }

    dispatch(
      setAvailCheckParams({
        roomIndex: roomOfRooms,
        ...queryParams,
      })
    );

    // Also merge to common params here in case user came straight to step 2
    dispatch(
      compose(setCommonSearchParams, getCommonSearchParams, merge(searchParams))(queryParams)
    );

    // Save the property code
    dispatch(setSelectedPropertyId(queryParams.propertyId));

    let cmsRoomDetailsRequest = api.getRoomDetails();
    let pmsAvailRequest = api.getAvailabilities({
      timeSliceTemplate: "OverNight", // Hardcoded
      unitGroupTypes: "BedRoom", // Hardcoded
      propertyId: queryParams.propertyId,
      arrival: queryParams.arrival,
      departure: queryParams.departure,
      adults: queryParams.adults,
      childrenAges: queryParams.childrenAges,
      promoCode: props.promoCode || commonSearchParams.promoCode, // Fetch from commonSearchParams is to handle multi room booking
      corporateCode: props.corporateCode || commonSearchParams.corporateCode, // Fetch from commonSearchParams is to handle multi room booking
    });

    Promise.all([pmsAvailRequest, cmsRoomDetailsRequest])
      .then((responses) => {
        const pmsAvailsRes = responses[0];
        const cmsRoomsRes = responses[1];

        let availabilities = pmsAvailsRes.data.offers || [];

        let restriction = pmsAvailsRes.data.restriction;
        setBookingRestrictions(restriction);

        if (pmsAvailsRes.status === 200 && availabilities.length === 0) {
          if (roomOfRooms === 1) {
            // handle no avail first room

            if (restriction) {
              // If there is a restriction, show the details
              // Note, the backend validates whether the restriction applies to this search
              props.onNotifOpen(getRestrictionMessage(restriction), {
                variant: "error",
                autoHideDuration: null,
              });
            } else {
              props.onNotifOpen("No availability found, please try again", {
                variant: "error",
                autoHideDuration: null,
              });
            }

            props.history.push(Routes.PLAN_STAY);
          } else {
            // subsequent rooms gives the option to checkout or start over
            handleNoAvailModalOpen();
          }
        } else if (pmsAvailsRes.data.success === false) {
          // Failure
          const message =
            pmsAvailsRes.data.message || "An unknown error occurred. Please try again.";
          props.onNotifOpen(message, {
            variant: "error",
            autoHideDuration: null,
          });
          props.history.push(Routes.PLAN_STAY);
        } else {
          // Set Room Details from CMS
          dispatch(setRoomDetails(cmsRoomsRes.data));

          // Set Availabilities from PMS
          availabilities = adjustAvailabilitiesWithReservationsInCart(availabilities);
          if (Object.keys(availabilities).length) {
            const preferredUnitGroups = queryParams.preferredUnitGroups?.split(",") || [];

            setAvailabilities(groupAvailsByUnitGroups(availabilities, preferredUnitGroups));
            setNights(availabilities[0].timeSlices.length);
          } else {
            // handles cart items fills up available rooms from pms
            handleNoAvailModalOpen();
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        props.onNotifOpen(error.message, { variant: "error" });
        props.history.push(Routes.PLAN_STAY);
      });
  };

  useEffect(() => {
    if (roomOfRooms <= roomReservations.length) {
      dispatch(removeRoomReservationFromBooking(roomOfRooms - 1));
    }
  }, [roomOfRooms, roomReservations]);

  useEffect(() => {
    // This gets called both on load and when the no avail model loads
    // the next room (ie adds same path to history)
    loadAvailability();
  }, [location.search]);

  return (
    <div className={classes.root}>
      <Navbar withProfile onNotifOpen={props.onNotifOpen} />
      <Grid container className={classes.mainContent}>
        <StepBreadcrumb currentStep={STEPS.CHOOSE_ROOMS.INDEX} />
        <Heading
          titleText={`${STEPS.CHOOSE_ROOMS.PAGE_TITLE} ${
            roomsOccupancy.length > 1 ? `(${roomOfRooms} of ${roomsOccupancy.length})` : ""
          }`}>
          <RoomSummaryBar
            arrival={availCheckParams.arrival}
            departure={availCheckParams.departure}
            guestCount={parseInt(availCheckParams.adults) + childrenCount(availCheckParams)}
          />
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleEditAvailCheckModalOpen}>
            Change
          </Button>
        </Heading>
        <LoadingSpinner loading={loading} />
        {!loading && bookingRestrictions ? (
          <Notice
            className={classes.notice}
            style="warning"
            message={getRestrictionMessage(bookingRestrictions)}
          />
        ) : null}
        <Grid
          container
          className={classNames(classes.splitContent, loading ? classes.hidden : null)}>
          <Grid item className={classes.detailsContainer}>
            {availabilities.map((availabilityGroup, i) => {
              const unitGroupCode = availabilityGroup.code;
              return (
                <Grid item className={classes.roomCardContainer} key={i}>
                  <RoomCard
                    index={i}
                    unitGroupAvailabilities={availabilityGroup.availabilities}
                    name={findRoomDetailsFromUnitGroupCode(unitGroupCode).name}
                    onReservationSelect={handleSelectReservation}
                    onShowRoomDetailsClick={handleShowRoomDetails}
                    imageUrl={findRoomDetailsFromUnitGroupCode(unitGroupCode).hero_image?.url}
                    gallery={findRoomDetailsFromUnitGroupCode(unitGroupCode).gallery}
                    unitGroupCode={unitGroupCode}
                    stayNights={nights}
                    selectable>
                    {findRoomDetailsFromUnitGroupCode(unitGroupCode).description}
                  </RoomCard>
                </Grid>
              );
            })}
          </Grid>
          <Basket className={classes.basket} onNotifOpen={props.onNotifOpen} />
        </Grid>
      </Grid>
      <RoomDetailsSideBarModal
        open={roomDetailsModalOpen}
        onClose={handleRoomDetailsModalClose}
        roomDetails={roomDetailsToShow}
        roomAvails={roomDetailsAvails}
        stayNights={nights}
        onReservationSelect={handleSelectReservation}
        selectedProperty={selectedProperty}
      />
      <NoAvailModal
        open={noAvailModalOpen}
        availCheckParams={availCheckParams}
        roomsOccupancy={roomsOccupancy}
        roomOfRoomsCounter={roomOfRooms}
      />
      <EditAvailCheckModal
        open={editAvailCheckModalOpen}
        onClose={handleEditAvailCheckModalClose}
      />
    </div>
  );
};

ChooseRoom.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onNotifOpen: PropTypes.func,
  promoCode: PropTypes.string,
  corporateCode: PropTypes.string,
  preferredUnitGroups: PropTypes.arrayOf(PropTypes.string),
};

export default ChooseRoom;
