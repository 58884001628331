import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.grey[900],
    color: "white",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: "flex",
    alignItems: "center",
    minHeight: "64px",
  },
  successHeader: {
    backgroundColor: theme.palette.success.main,
  },
  headerIcon: {
    fontSize: "36px",
    marginRight: theme.spacing(1.5),
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2.5)}px`,
  },
}));

const SectionWithHeader = (props) => {
  const classes = useStyles();

  return (
    <div className={props.className}>
      <Grid
        container
        className={classNames(
          classes.header,
          props.color === "success" ? classes.successHeader : null
        )}>
        <span className={classes.headerIcon}>{props.icon}</span>
        <Typography variant="h5">{props.title}</Typography>
      </Grid>

      <Paper className={classes.paper}>{props.children}</Paper>
    </div>
  );
};

SectionWithHeader.defaultProps = {
  background: "default",
};

SectionWithHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
  color: PropTypes.oneOf(["default", "success"]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default SectionWithHeader;
