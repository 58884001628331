import { createTheme } from "@material-ui/core/styles";

export const baseTheme = createTheme({
  palette: {
    type: "light",
    background: {
      default: "#FAF2E1",
      componentBackground1: "#FAF2E1",
      componentBackground2: "#FAF2E1",
      componentBackground3: "#FAF2E1",
      onDark: {
        default: "#2C1D28",
      },
    },
    primary: {
      main: "#c83510",
      light: "#ef5731",
      dark: "#94270c",
      contrastText: "#FAF2E1",
      hover: "rgba(200, 53, 16, 0.04)",
      selected: "rgba(200, 53, 16, 0.08)",
      active: "rgba(200, 53, 16, 0.24)",
      border: "rgba(200, 53, 16, 0.5)",
      containedActive: "rgba(200, 53, 16, 0.24)",
    },
    info: {
      main: "#28434A",
      dark: "#142226",
      light: "#416d78",
      contrastText: "#FAF2E1",
      darkText: "#20363c",
      lightBg: "#ebf2f4",
    },
    success: {
      main: "#558d84",
      dark: "#406a63",
      light: "#7aafa6",
      contrastText: "#FAF2E1",
      darkText: "#233935",
      lightBg: "#ecf3f2",
    },
    error: {
      main: "#e63612",
      dark: "#b2290e",
      light: "#f1694e",
      contrastText: "#FAF2E1",
      darkText: "#551407",
      lightBg: "#fde7e3",
    },
    warning: {
      main: "#E19421",
      dark: "#b27418",
      light: "#eab260",
      contrastText: "#2C1D28",
      darkText: "#51350b",
      lightBg: "#fbf2e4",
      onDark: {
        main: "#eab260",
        dark: "#f2d19f",
        light: "#e39b2e",
        contrastText: "#2C1D28",
        darkText: "#fbf2e4",
        lightBg: "#51350b",
      },
    },
    text: {
      red: "#c83510",
      heading: "#2C1D28",
      primary: "rgba(44, 29, 40, 0.87)",
      secondary: "rgba(44, 29, 40, 0.6)",
      disabled: "rgba(44, 29, 40, 0.38)",
      onDark: {
        primary: "rgba(255, 254, 240, 0.87)",
        secondary: "rgba(255, 254, 240, 0.6)",
        disabled: "rgba(255, 254, 240, 0.38)",
      },
    },
    other: {
      backdrop: "rgba(44, 29, 40, 0.54)",
      disabledBackground: "#E0DDD4",
      activeRating: "#e19421",
      dropShadow: "#2c1d28",
      imageOverlay: "#121212",
      lineDetail: "rgba(44, 29, 40, 0.2)",
      onDark: {
        backdrop: "rgba(18, 18, 18, 0.54)",
        disabledBackground: "#5B4F56",
        dropShadow: "#2c1d28",
        imageOverlay: "#121212",
      },
    },
    grey: {
      0: "#FAF2E1",
      50: "#F5F3E7",
      100: "#E0DDD4",
      200: "#CCC7C0",
      300: "#ADA6A3",
      400: "#989190",
      500: "#847B7D",
      600: "#6F6569",
      700: "#5B4F56",
      800: "#463943",
      900: "#2C262B",
      1000: "#121212",
    },
    divider: "rgba(44, 29, 40, 0.2)",

    // Custom mollies colour palette
    custom: {
      offWhite: "#FAF2E1",
      lightTeal: "#558d84",
      //  neutralTeal: "#A8C0AB",
      //  brightTeal: "#00807A",
      mustard: "#e19421",
      coral: "#e63612",
      molliesRed: "#c83510",
      //  mainGrey: "#20262C",
      nightBlue: "#28434a",
      plum: "#463943",
      greenCountry: "#4e5f4d",
    },
  },
  typography: {
    fontFamily: `"Hanken Grotesk", "Helvetica", "Arial", sans-serif`,
    fontOpticalSizing: "auto",
    h1: {
      fontFamily: `Paradizo, "Helvetica", "Arial", sans-serif`,
      fontSize: "90px",
      lineHeight: "1.2em",
      letterSpacing: "0.08em",
      fontWeight: 400,
      textTransform: "none",
    },
    h2: {
      fontFamily: `Paradizo, "Helvetica", "Arial", sans-serif`,
      fontSize: "63px",
      lineHeight: "1.25em",
      letterSpacing: "0.08em",
      fontWeight: 400,
      textTransform: "none",
    },
    h3: {
      fontFamily: `Paradizo, "Helvetica", "Arial", sans-serif`,
      fontSize: "45px",
      lineHeight: "1.3em",
      letterSpacing: "0.06em",
      fontWeight: 400,
      textTransform: "none",
    },
    h4: {
      fontFamily: `Paradizo, "Helvetica", "Arial", sans-serif`,
      fontSize: "32px",
      lineHeight: "1.4em",
      letterSpacing: "0.06em",
      fontWeight: 400,
      textTransform: "none",
    },
    h5: {
      fontFamily: `Paradizo, "Helvetica", "Arial", sans-serif`,
      fontSize: "23px",
      lineHeight: "1.5em",
      letterSpacing: "0.08em",
      fontWeight: 400,
      textTransform: "none",
    },
    h6: {
      fontFamily: `"Hanken Grotesk", "Helvetica", "Arial", sans-serif`,
      fontOpticalSizing: "auto",
      fontSize: "19px",
      lineHeight: "1.5em",
      letterSpacing: "0.08em",
      fontWeight: 400,
      textTransform: "none",
    },
    subtitle1: {
      fontFamily: `"Hanken Grotesk", "Helvetica", "Arial", sans-serif`,
      fontOpticalSizing: "auto",
      fontSize: "16px",
      lineHeight: "1.5em",
      letterSpacing: "0.19px",
      fontWeight: 700,
      textTransform: "none",
    },
    subtitle2: {
      fontFamily: `"Hanken Grotesk", "Helvetica", "Arial", sans-serif`,
      fontOpticalSizing: "auto",
      fontSize: "14px",
      lineHeight: "1.5em",
      letterSpacing: "0.02em",
      fontWeight: 700,
      textTransform: "none",
    },
    body1: {
      fontFamily: `"Hanken Grotesk", "Helvetica", "Arial", sans-serif`,
      fontOpticalSizing: "auto",
      fontSize: "18px",
      lineHeight: "1.5em",
      letterSpacing: "0em",
      fontWeight: 400,
    },
    body2: {
      fontFamily: `"Hanken Grotesk", "Helvetica", "Arial", sans-serif`,
      fontOpticalSizing: "auto",
      fontSize: "16px",
      lineHeight: "1.5em",
      letterSpacing: "0em",
      fontWeight: 400,
    },
    body3: {
      fontFamily: `"Hanken Grotesk", "Helvetica", "Arial", sans-serif`,
      fontOpticalSizing: "auto",
      fontSize: "14px",
      lineHeight: "1.5em",
      letterSpacing: "0em",
      fontWeight: 400,
    },
    button: {
      fontFamily: `"Hanken Grotesk", "Helvetica", "Arial", sans-serif`,
      fontOpticalSizing: "auto",
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "1.5em",
      letterSpacing: "0.02em",
      textTransform: "none",
      fontWeight: 500,
    },
    button3: {
      fontFamily: `"Hanken Grotesk", "Helvetica", "Arial", sans-serif`,
      fontOpticalSizing: "auto",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "1.5em",
      letterSpacing: "0.02em",
      textTransform: "none",
      fontWeight: 500,
    },
    caption: {
      fontFamily: `"Hanken Grotesk", "Helvetica", "Arial", sans-serif`,
      fontOpticalSizing: "auto",
      fontSize: "12px",
      lineHeight: "1.2em",
      letterSpacing: "0.06em",
      textTransform: "none",
      fontWeight: 400,
    },
    overline: {
      fontFamily: `"Hanken Grotesk", "Helvetica", "Arial", sans-serif`,
      fontOpticalSizing: "auto",
      fontSize: "12px",
      lineHeight: "2.5em",
      letterSpacing: "0.12em",
      textTransform: "uppercase",
      fontWeight: 500,
    },
  },
  shadows: [
    "none", // elevation0
    "0px 1px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)", // elevation1
    "0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 2px 5px rgba(0, 0, 0, 0.12)", //evevation2
    "0px 3px 3px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)", //evevation3
    "0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)", //evevation4
    "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12);", //evevation5
    "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)", //evevation6
    "0px 4px 5px rgba(0, 0, 0, 0.2), 0px 7px 10px rgba(0, 0, 0, 0.14), 0px 2px 16px rgba(0, 0, 0, 0.12)", //evevation7
    "0px 5px 5px rgba(0, 0, 0, 0.2), 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12)", //evevation8
    "0px 5px 6px rgba(0, 0, 0, 0.2), 0px 9px 12px rgba(0, 0, 0, 0.14), 0px 3px 16px rgba(0, 0, 0, 0.12)", //evevation9
    "0px 6px 6px rgba(0, 0, 0, 0.2), 0px 10px 14px rgba(0, 0, 0, 0.14), 0px 4px 18px rgba(0, 0, 0, 0.12)", //evevation10
    "0px 6px 7px rgba(0, 0, 0, 0.2), 0px 11px 15px rgba(0, 0, 0, 0.14), 0px 4px 20px rgba(0, 0, 0, 0.12)", //evevation11
    "0px 7px 8px rgba(0, 0, 0, 0.2), 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12)", //evevation12
    "0px 7px 8px rgba(0, 0, 0, 0.2), 0px 13px 19px rgba(0, 0, 0, 0.14), 0px 5px 24px rgba(0, 0, 0, 0.12)", //evevation13
    "0px 7px 9px rgba(0, 0, 0, 0.2), 0px 14px 21px rgba(0, 0, 0, 0.14), 0px 5px 26px rgba(0, 0, 0, 0.12)", //evevation14
    "0px 8px 9px rgba(0, 0, 0, 0.2), 0px 15px 22px rgba(0, 0, 0, 0.14), 0px 6px 28px rgba(0, 0, 0, 0.12)", //evevation15
    "0px 8px 10px rgba(0, 0, 0, 0.2), 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12)", //evevation16
    "0px 8px 11px rgba(0, 0, 0, 0.2), 0px 17px 26px rgba(0, 0, 0, 0.14), 0px 6px 32px rgba(0, 0, 0, 0.12)", //evevation17
    "0px 9px 11px rgba(0, 0, 0, 0.2), 0px 18px 28px rgba(0, 0, 0, 0.14), 0px 7px 34px rgba(0, 0, 0, 0.12)", //evevation18
    "0px 9px 12px rgba(0, 0, 0, 0.2), 0px 19px 29px rgba(0, 0, 0, 0.14), 0px 7px 36px rgba(0, 0, 0, 0.12)", //evevation19
    "0px 10px 13px rgba(0, 0, 0, 0.2), 0px 20px 31px rgba(0, 0, 0, 0.14), 0px 8px 38px rgba(0, 0, 0, 0.12)", //evevation20
    "0px 10px 13px rgba(0, 0, 0, 0.2), 0px 21px 33px rgba(0, 0, 0, 0.14), 0px 8px 40px rgba(0, 0, 0, 0.12)", //evevation21
    "0px 10px 14px rgba(0, 0, 0, 0.2), 0px 22px 35px rgba(0, 0, 0, 0.14), 0px 8px 42px rgba(0, 0, 0, 0.12)", //evevation22
    "0px 11px 14px rgba(0, 0, 0, 0.2), 0px 23px 36px rgba(0, 0, 0, 0.14), 0px 9px 44px rgba(0, 0, 0, 0.12)", //evevation23
    "0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)", //evevation24
  ],
  /* special shadow to match nav bar in mollies.com site */
  navBarShadow: "1px 1px 4px rgb(0 0 0 / 10%)",
  breakpoints: {
    values: {
      sidebar: 520,
      tablet: 770,
      desktop: 1110,
    },
  },
  contentSize: {
    pageWidth: "80%",
    maxPageWidth: "1120px",
    maxContentWidth: "1440px",
    mobilePageWidth: "90%",
  },
  shape: {
    borderRadius: "0px",
    buttonRadius: "4px",
  },
  mixins: {
    toolbar: {
      minHeight: 114,
    },
  },
});

// Mobile typography styles
baseTheme.typography = {
  ...baseTheme.typography,
  h2: {
    ...baseTheme.typography.h2,
    [baseTheme.breakpoints.down(baseTheme.breakpoints.values.tablet)]: {
      fontSize: "30px",
      lineHeight: "38px",
    },
  },
  h3: {
    ...baseTheme.typography.h3,
    [baseTheme.breakpoints.down(baseTheme.breakpoints.values.tablet)]: {
      fontSize: "23px",
      lineHeight: "26px",
      letterSpacing: "1px",
    },
  },
  body1: {
    ...baseTheme.typography.body1,
    [baseTheme.breakpoints.down(baseTheme.breakpoints.values.tablet)]: {
      fontSize: "16px",
    },
  },
  subtitle1: {
    ...baseTheme.typography.subtitle1,
    [baseTheme.breakpoints.down(baseTheme.breakpoints.values.tablet)]: {
      fontSize: "16px",
      lineHeight: "23px",
    },
  },
};

baseTheme.overrides.MuiPaper = {
  root: {
    borderRadius: "0px",
    boxShadow: "none",
  },
  elevation1: {
    boxShadow: "none",
    border: `1px solid ${baseTheme.palette.other.lineDetail}`,
    backgroundColor: baseTheme.palette.background.default,
  },
  elevation8: {
    backgroundColor: baseTheme.palette.custom.offWhite,
  },
};

baseTheme.overrides.MuiButton = {
  root: {
    borderRadius: "4px",
    padding: "8px 16px", // medium size
  },
  contained: {
    boxShadow: "none",
    padding: "8px 16px", // medium size
  },
  outlined: {
    padding: "8px 16px", // medium size
  },
  sizeSmall: {
    ...baseTheme.typography.button2,
    padding: "8px 16px",
  },
  sizeLarge: {
    // Mobile style - large doesnt exist on mobile - use medium
    padding: "8px 16px",

    [baseTheme.breakpoints.up(baseTheme.breakpoints.values.tablet)]: {
      padding: "12px 24px",
      borderRadius: "4px",
    },
  },
  containedPrimary: {
    "&:hover": {
      backgroundColor: baseTheme.palette.grey[900],
      boxShadow: "none",
    },
  },
};

baseTheme.overrides.MuiInput = {
  root: {
    ...baseTheme.typography.body2,
    fontSize: "16px", // Needed to prevent zoom on ios safari
  },
};

baseTheme.overrides.MuiOutlinedInput = {
  root: {
    backgroundColor: "#FFFFFF",
    "& $notchedOutline": {
      borderColor: baseTheme.palette.other.lineDetail,
    },
    "&:hover $notchedOutline": {
      borderColor: baseTheme.palette.other.lineDetail,
    },
  },
};

baseTheme.overrides.MuiPopover = {
  paper: {
    backgroundColor: baseTheme.palette.background.default,
  },
};

baseTheme.overrides.MuiFormControl = {
  root: {
    minWidth: "80px",
  },
};

baseTheme.overrides.MuiStepContent = {
  root: {
    borderColor: baseTheme.palette.other.lineDetail,
  },
};

baseTheme.overrides.MuiStepConnector = {
  line: {
    borderColor: baseTheme.palette.other.lineDetail,
  },
};

baseTheme.overrides.MuiStepIcon = {
  root: {
    "&$active": {
      color: baseTheme.palette.text.primary,
    },
    text: {
      fill: baseTheme.palette.text.onDark.primary,
    },
  },
};

export default baseTheme;
